import { toPairs, find, last, prop, compose } from 'ramda'
import { isNumber } from 'ramda-adjunct'

const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME
const defaultUploadPreset = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET

const paramNames = {
  audio_codec: 'ac',
  video_codec: 'vc',
  start_offset: 'so',
  duration: 'du',
  fl_attachment: 'fl_attachment',
}

const createTransformation = options => toPairs(options).map(([ name, value ]) => {
  const key = (paramNames[name] || name[0])
  const delim = key.includes('_') ? ':' : '_'
  return key + delim + value
}).join(',')

export const exists = compose(Boolean, prop('public_id'))
export const isImageType = image => image.resource_type !== 'video' && image.format !== 'gif'
export const isVideoType = image => image.resource_type == 'video' || image.format !== 'gif'

export const firstValidResource = find(prop('public_id'))

export const originalFilename = (image) => {
  if (!exists(image)) {
    return ''
  }

  const { original_filename, public_id } = image
  const ext = last(public_id.split('.'))
  return original_filename ? [original_filename, ext].filter(Boolean).join('.') : public_id
}

export const url = (options, image) => {
  if (!image) {
    image = options
    options = null
  }

  if (!image) {
    return ''
  }

  // This is not actually a cloudinary image.
  if (typeof image === 'string') {
    return image
  }

  const {
    public_id: id,
    secure_url: orig,
  } = image

  if (!orig) {
    return ''
  }

  if (!options) {
    return orig
  }

  // Add video options for videos (and gifs).
  const isImage = isImageType(image)
  if (!isImage && !options.format) {
    options.format = 'webm'
  }

  if (isNumber(options.width)) {
    options.width = Math.floor(options.width)
  }

  if (isNumber(options.height)) {
    options.height = Math.floor(options.height)
  }

  let base = orig.match(/.*\/upload/)[0]

  const transformation = createTransformation(options)
  const extension = options.format && options.format !== 'auto' ? `.${options.format}` : ''
  let urlComponents = [ base, transformation, image.version && `v${image.version}`, id ]
  return urlComponents.filter(Boolean).join('/') + extension
}

export const upload = async (file, { signUpload, uploadPreset = defaultUploadPreset, publicId = '' }) => {
  let [type, extension] = file.type.split('/')
  // Support uploading normal files to Cloudinary.
  if (type === 'application') {
    // PDFs should be stored as images.
    type = extension === 'pdf' ? 'image' : 'raw'
  }
  // Here we're handling signed uploads directly to cloudinary.
  const signed = await signUpload({ upload_preset: uploadPreset, public_id: publicId })
  // Construct request form data.
  const formData = new FormData()
  formData.set('file', file)
  formData.set('upload_preset', uploadPreset)
  if (publicId) {
    formData.set('public_id', publicId)
  }
  Object.keys(signed).forEach((key) => {
    formData.set(key, signed[key])
  })
  const response = await fetch(`https://api.cloudinary.com/v1_1/${cloudName}/${type}/upload`, {
    method: 'POST',
    body: formData,
  })
  const image = response ? await response.json() : null
  return image
}
