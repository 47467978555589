import React from 'react'
import { Button, makeStyles } from '@material-ui/core'
import { omit } from 'ramda'

const useStyles = makeStyles((theme) => ({
  danger: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.light,
  },
  emphasized: {
    backgroundColor: theme.palette.grey['400'],
  },
}))

const ThemedButton = (props) => {
  const classes = useStyles()

  let colorStyle = classes[props.color]
  if (colorStyle) {
    props = omit(['color'], props)
  }

  return <Button classes={{ root: colorStyle }} {...props} />
}

ThemedButton.propTypes = Button.propTypes

export default ThemedButton
