import React, { Suspense, lazy } from 'react'
import { connect } from '@cerebral/react'
import { state, sequences } from 'cerebral'
import { Typography } from '@material-ui/core'

import Home from './home/Home'
import Organization from './home/Organization'
import Settings from './home/Settings'
import ContactInfoSettings from './home/ContactInfo'
import ContactSettings from './home/ContactSettings'
import { routeAccess } from '../../modules/route'
import { useEffect } from 'react'
import LoadingScreen from '../elements/LoadingScreen'

const NotFound = () => {
  return <Typography variant="h2">Not found</Typography>
}

const permitted = () => true

const components = {
  toRoot: Home,
  toLogin: lazy(() => import(/* webpackChunkName: "Login" */ './account/Login')),
  toAccountSetting: lazy(() => import(/* webpackChunkName: "AccountSettings" */ './account-settings/Form')),
  toAlbums: lazy(() => import(/* webpackChunkName: "Albums" */ './albums/List')),
  toAlbum: lazy(() => import(/* webpackChunkName: "Albums" */ './albums/Form')),
  toAlerts: lazy(() => import(/* webpackChunkName: "Alerts" */ './alerts/List')),
  toAlert: lazy(() => import(/* webpackChunkName: "Alerts" */ './alerts/Form')),
  toAwards: lazy(() => import(/* webpackChunkName: "Awards" */ './appreciation-awards/List')),
  toAward: lazy(() => import(/* webpackChunkName: "Awards" */ './appreciation-awards/Form')),
  toCapacityLocations: lazy(() => import(/* webpackChunkName: "Capacity" */ './capacity-locations/List')),
  toCapacityLocation: lazy(() => import(/* webpackChunkName: "Capacity" */ './capacity-locations/Form')),
  toCalendar: lazy(() => import(/* webpackChunkName: "Calendars" */ './calendars/Form')),
  toContactInfo: lazy(() => import(/* webpackChunkName: "Contact Info" */ './contact-infos/Form')),
  toContactInfoSettings: ContactInfoSettings,
  toContactSettings: ContactSettings,
  toDisplays: lazy(() => import(/* webpackChunkName: "Signage" */ './displays/List')),
  toDisplay: lazy(() => import(/* webpackChunkName: "Signage" */ './displays/Form')),
  toEvents: lazy(() => import(/* webpackChunkName: "Events" */ './events/List')),
  toEvent: lazy(() => import(/* webpackChunkName: "Events" */ './events/Form')),
  toEventCalendar: lazy(() => import(/* webpackChunkName: "Calendar" */ './events/Calendar')),
  toEventAttendances: lazy(() => import(/* webpackChunkName: "Attendance" */ './event-attendances/List')),
  toEventAttendance: lazy(() => import(/* webpackChunkName: "Attendance" */ './event-attendances/Form')),
  toEventRsvps: lazy(() => import(/* webpackChunkName: "Rsvps" */ './event-rsvps/List')),
  toEventRsvp: lazy(() => import(/* webpackChunkName: "Attendance" */ './event-rsvps/Form')),
  toEventInstances: lazy(() => import(/* webpackChunkName: "Attendance" */ './event-instances/List')),
  toEventInstancesSummary: lazy(() => import(/* webpackChunkName: "Attendance" */ './event-instances/Summary')),
  toInvitations: lazy(() => import(/* webpackChunkName: "People" */ './invitations/List')),
  toInvitation: lazy(() => import(/* webpackChunkName: "People" */ './invitations/Form')),
  toInvitationsUpload: lazy(() => import(/* webpackChunkName: "BulkInvitation" */ './invitations/Upload')),
  toLibraryItems: lazy(() => import(/* webpackChunkName: "ContentLibrary" */ './library-items/List')),
  toLibraryItem: lazy(() => import(/* webpackChunkName: "ContentLibrary" */ './library-items/View')),
  toLifts: lazy(() => import(/* webpackChunkName: "Lifts" */ './lifts/List')),
  toLift: lazy(() => import(/* webpackChunkName: "Lifts" */ './lifts/Form')),
  toLocationVehicles: lazy(() => import(/* webpackChunkName: "Vehicles" */ './location-vehicles/List')),
  toLocationVehicleView: lazy(() => import(/* webpackChunkName: "Vehicles" */ './location-vehicles/View')),
  toLocationVehicle: lazy(() => import(/* webpackChunkName: "Vehicles" */ './location-vehicles/Form')),
  toLocationVehicleDrivers: lazy(() => import(/* webpackChunkName: "Vehicles" */ './location-vehicle-drivers/List')),
  toLocationVehicleMaintenanceSchedule: lazy(() => import(/* webpackChunkName: "Vehicles" */ './location-vehicle-maintenance-schedules/Form')),
  toLocationVehicleShifts: lazy(() => import(/* webpackChunkName: "Vehicle Shifts" */ './location-vehicle-shifts/List')),
  toLocationRoutes: lazy(() => import(/* webpackChunkName: "Vehicles" */ './location-routes/List')),
  toLocationRouteView: lazy(() => import(/* webpackChunkName: "Vehicles" */ './location-routes/View')),
  toLocationRoute: lazy(() => import(/* webpackChunkName: "Vehicles" */ './location-routes/Form')),
  toLocationRouteRequests: lazy(() => import(/* webpackChunkName: "RouteRequests" */ './location-route-requests/List')),
  toLocationRouteRequestView: lazy(() => import(/* webpackChunkName: "RouteRequestView" */ './location-route-requests/View')),
  toLocationRouteRequest: lazy(() => import(/* webpackChunkName: "RouteRequests" */ './location-route-requests/Form')),
  toLocationRouteDispatchRequests: lazy(() => import(/* webpackChunkName: "RouteDispatchRequests" */ './location-route-dispatch-requests/List')),
  toMapPoints: lazy(() => import(/* webpackChunkName: "MapPoints" */ './map-points/List')),
  toMapPoint: lazy(() => import(/* webpackChunkName: "MapPoints" */ './map-points/Form')),
  toMapAreas: lazy(() => import(/* webpackChunkName: "MapPoints" */ './map-areas/List')),
  toMapArea: lazy(() => import(/* webpackChunkName: "MapPoints" */ './map-areas/Form')),
  toMapCategories: lazy(() => import(/* webpackChunkName: "MapPoints" */ './map-categories/List')),
  toMapCategorie: lazy(() => import(/* webpackChunkName: "MapPoints" */ './map-categories/Form')),
  toMedias: lazy(() => import(/* webpackChunkName: "Media" */ './medias/List')),
  toMedia: lazy(() => import(/* webpackChunkName: "Media" */ './medias/Form')),
  toMealsCalendar: lazy(() => import(/* webpackChunkName: "Calendar" */ './events/Calendar')),
  toMealsUpload: lazy(() => import(/* webpackChunkName: "MealUpload" */ './events/MealsUpload')),
  toMeals: lazy(() => import(/* webpackChunkName: "Events" */ './events/List')),
  toMeal: lazy(() => import(/* webpackChunkName: "Events" */ './events/Form')),
  toMealLocation: lazy(() => import(/* webpackChunkName: "MealLocations" */ './meal-locations/Form')),
  toMobileWelcomes: lazy(() => import(/* webpackChunkName: "MobileWelcome" */ './mobile-welcomes/List')),
  toMobileWelcome: lazy(() => import(/* webpackChunkName: "MobileWelcome" */ './mobile-welcomes/Form')),
  toNotifications: lazy(() => import(/* webpackChunkName: "Notification" */ './notifications/List')),
  toNotificationView: lazy(() => import(/* webpackChunkName: "Notification" */ './notifications/View')),
  toNotification: lazy(() => import(/* webpackChunkName: "Notification" */ './notifications/Form')),
  toPublications: lazy(() => import(/* webpackChunkName: "Publications" */ './publications/List')),
  toPublication: lazy(() => import(/* webpackChunkName: "Publications" */ './publications/Form')),
  toOrganization: Organization,
  toSettings: Settings,
  toPeople: lazy(() => import(/* webpackChunkName: "People" */ './people/List')),
  toPerson: lazy(() => import(/* webpackChunkName: "People" */ './people/Form')),
  toPlaylists: lazy(() => import(/* webpackChunkName: "Signage" */ './kiosks/List')),
  toPlaylist: lazy(() => import(/* webpackChunkName: "Signage" */ './kiosks/Form')),
  toProfile: lazy(() => import(/* webpackChunkName: "People" */ './profiles/Form')),
  toPrices: lazy(() => import(/* webpackChunkName: "Prices" */ './prices/List')),
  toPrice: lazy(() => import(/* webpackChunkName: "Prices" */ './prices/Form')),
  toPricings: lazy(() => import(/* webpackChunkName: "Prices" */ './pricings/List')),
  toPricing: lazy(() => import(/* webpackChunkName: "Prices" */ './pricings/Form')),
  toReservations: lazy(() => import(/* webpackChunkName: "Reservations" */ './reservations/List')),
  toReservation: lazy(() => import(/* webpackChunkName: "Reservations" */ './reservations/Form')),
  toResources: lazy(() => import(/* webpackChunkName: "Resources" */ './resources/List')),
  toResource: lazy(() => import(/* webpackChunkName: "Resources" */ './resources/Form')),
  toRideProducts: lazy(() => import(/* webpackChunkName: "RideProducts" */ './ride-products/List')),
  toRideProduct: lazy(() => import(/* webpackChunkName: "RideProducts" */ './ride-products/Form')),
  toRideProductSubscriptions: lazy(() => import(/* webpackChunkName: "RideProducts" */ './ride-product-subscriptions/List')),
  toSnowStats: lazy(() => import(/* webpackChunkName: "Snow" */ './snow-stats/Form')),
  toSnowReports: lazy(() => import(/* webpackChunkName: "Snow" */ './snow-reports/List')),
  toSnowReport: lazy(() => import(/* webpackChunkName: "Snow" */ './snow-reports/Form')),
  toSurveys: lazy(() => import(/* webpackChunkName: "Surveys" */ './surveys/List')),
  toSurvey: lazy(() => import(/* webpackChunkName: "Surveys" */ './surveys/Form')),
  toSurveyResponses: lazy(() => import(/* webpackChunkName: "Surveys" */ './surveyResponses/List')),
  toTrails: lazy(() => import(/* webpackChunkName: "Trails" */ './trails/List')),
  toTrail: lazy(() => import(/* webpackChunkName: "Trails" */ './trails/Form')),
  toUpdates: lazy(() => import(/* webpackChunkName: "Updates" */ './updates/List')),
  toUpdate: lazy(() => import(/* webpackChunkName: "Updates" */ './updates/Form')),
}

const Router = connect(
  {
    currentRoute: state`route.key`,
    packages: state`account.packages`,
    checked: state`account.checked`,
    toRoot: sequences`route.toRoot`,
  },
  ({ currentRoute, packages, checked, toRoot }) => {
    const Component = components[currentRoute] || NotFound
    const accessGranted = (routeAccess[currentRoute] || permitted)(packages)

    useEffect(() => {
      if (checked && !accessGranted) {
        toRoot()
      }
    }, [checked, accessGranted])

    return checked ? <Suspense fallback={<LoadingScreen />}>{accessGranted && <Component />}</Suspense> : <LoadingScreen />
  }
)

export default Router
