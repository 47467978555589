import React from 'react'
import { Card, makeStyles } from '@material-ui/core'
import classnames from 'classnames'

const useStyles = makeStyles((theme) => ({
  Card: {
    borderWidth: 1,
    borderStyle: 'solid',
    borderColor: theme.palette.grey[200],
  },
  CardPadding: {
    padding: 48,
  },
  CardLarge: {
    borderRadius: 8,
  },
  CardHover: {
    '&:hover': {
      borderColor: theme.palette.grey[300],
      backgroundColor: theme.palette.grey[50],
    },
  },
}))

const FlatCard = ({ classes: propClasses = {}, padding, large, hover, ...attrs }) => {
  const classes = useStyles()

  return (
    <Card
      elevation={0}
      classes={{
        ...propClasses,
        root: classnames([classes.Card, hover && classes.CardHover, padding && classes.CardPadding, large && classes.CardLarge, propClasses.root]),
      }}
      {...attrs}
    />
  )
}

export default FlatCard
