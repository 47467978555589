const numeral = require('numeral')

const name = ({ display, first, last } = {}) => (display || [first, last].filter(Boolean).join(' ') || '').trim()
const initials = ({ first, last } = {}) =>
  [first, last]
    .map((name) => name && name[0])
    .filter(Boolean)
    .join('')

const plural = (count, singular, { showCount } = {}) => `${showCount ? `${count} ` : ''}${count === 1 ? singular : `${singular}s`}`
const currency = (value) => numeral(value).format('$0,0.00')

const address = (address) => {
  if (typeof address === 'string') {
    return address
  }
  const { street1, street2, city, state, zip, country } = address || {}
  return [street1, street2, city, state, zip, country].filter(Boolean).join(', ')
}

const phoneMaskNoExtension = [
  {
    mask: '000-000-0000',
    lazy: false,
  },
]

const phoneMask = [
  ...phoneMaskNoExtension,
  {
    mask: '000-000-0000 x0[0000]',
    lazy: false,
  },
]

const format = { name, initials, plural, currency, phoneMask, phoneMaskNoExtension, address }

module.exports = format
