const { default: axios } = require('axios')
const { REACT_APP_MAPBOX_ACCESS_TOKEN } = process.env

const params = {
  access_token: REACT_APP_MAPBOX_ACCESS_TOKEN,
}

const geoClient = axios.create({
  baseURL: 'https://api.mapbox.com/geocoding/v5/mapbox.places',
  params,
})

const tilesClient = axios.create({
  // Tileset from https://docs.mapbox.com/help/tutorials/create-a-timezone-finder-with-mapbox-tilequery-api/
  baseURL: 'https://api.mapbox.com/v4/examples.4ze9z6tv/tilequery',
  params,
})

const searchClient = axios.create({
  baseURL: `https://api.mapbox.com/search/searchbox/v1`,
  params,
})

const getGeoCoordinates = async ({ street1, street2, city, state, zip } = {}) => {
  const addr = encodeURIComponent([street1, street2, city, state, zip].filter(Boolean).join(' '))
  const { data: { features: [{ center: [longitude, latitude] = [] } = {}] = [] } = {} } = await geoClient.get(`/${addr}.json`)
  return { latitude, longitude }
}

const getTimezone = async ({ latitude, longitude }) => {
  const { data: { features: [{ properties = {} }] = [] } = {} } = await tilesClient.get(`/${longitude},${latitude}.json`)
  return properties.TZID
}

const getSearchboxSuggestions = async (params) => {
  const searchParams = new URLSearchParams({
    language: 'en',
    country: 'us',
    limit: '7',
    ...params,
  })
  const { data: { suggestions = [] } = {} } = await searchClient.get(`/suggest?${searchParams}`)
  return suggestions
}

module.exports = {
  geoClient,
  searchClient,
  getGeoCoordinates,
  getTimezone,
  getSearchboxSuggestions,
}
