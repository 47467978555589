const yup = require('yup')
const { uploadSchema } = require('./cloudinary-schema')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  _id: yup.mixed(),
  title: yup.string().max(100).meta({ maxLength: 100 }).default(''),
  body: yup.string().max(240).meta({ maxLength: 240 }).ensure(),
  image: uploadSchema,
  published: yup.boolean().nullable().default(false),
  startAt: yup.date().when('published', {
    is: true,
    then: yup
      .date()
      .required()
      .default(() => new Date()),
  }),
  endAt: yup
    .date()
    .when('published', {
      is: true,
      then: yup
        .date()
        .required()
        .min(yup.ref('startAt'))
        .default(() => new Date()),
    })
    .min(yup.ref('startAt')),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const mobileWelcomeSchemaPartial = yup.object().shape(shape)
const mobileWelcomeSchema = mobileWelcomeSchemaPartial.shape(requiredFields(['title', 'body'])(shape))

module.exports = { mobileWelcomeSchema, mobileWelcomeSchemaPartial }
