import axios from 'axios'
import { captureException } from './errors'

const designHuddleDomain = process.env.REACT_APP_DESIGN_HUDDLE_DOMAIN

export const loadEditorSDK = () => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = 'https://cdn.designhuddle.com/jssdk/v1/lib.min.js'

  const existing = document.getElementsByTagName('script')[0]
  existing.parentNode.insertBefore(script, existing)
}

export const templateCategories = { newsletters: 1, flyers: 2, digitalSignages: 3 }

export class DesignHuddleClient {
  constructor(access_token) {
    this.designHuddleClient = axios.create({
      baseURL: `https://${designHuddleDomain}`,
      headers: { authorization: `Bearer ${access_token}` },
    })
  }

  startProjectExport = async (project_id, filename, callback) => {
    try {
      const { data: result } = await this.designHuddleClient.post(`/api/projects/${project_id}/export`, { format: 'pdf', filename, customizations: {} })
      if (result && result.success && result.data) {
        callback(null, result.data.job_id)
      } else {
        callback(new Error('Error starting the design huddle project export.'))
      }
    } catch (error) {
      captureException(error)
    }
  }

  pollJobExport = async (project_id, job_id, callback) => {
    try {
      const { data: result } = await this.designHuddleClient.get(`/api/projects/${project_id}/export/jobs/${job_id}`, {})
      if (!result.data.completed) {
        setTimeout(() => this.pollJobExport(project_id, job_id, callback), 1000)
      } else {
        callback(null, result.data)
      }
    } catch (error) {
      captureException(error)
    }
  }

  exportProject = async (projectId, title) => {
    return new Promise((resolve, reject) => {
      this.startProjectExport(projectId, title, (error, jobId) => {
        if (error) {
          reject(error)
          return
        }
        this.pollJobExport(projectId, jobId, async (error, editorProject) => {
          if (error) {
            reject(error)
            return
          }
          resolve(editorProject)
        })
      })
    })
  }

  cloneProject = async (project_id, project_title) => {
    try {
      const { data: result } = await this.designHuddleClient.post(`/api/projects/${project_id}/clone`, { project_title })
      if (result) {
        return result.data.project_id
      }
    } catch (error) {
      captureException(error)
    }
  }
}

export default {
  loadEditorSDK,
  DesignHuddleClient,
}
