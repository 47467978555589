const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')
const { uploadSchema } = require('./cloudinary-schema')
const mobileUserTypes = require('../mobile-user-types')
const displayLocation = require('./fields/display-location')
const scheduleDate = require('./fields/shedule-date')
const { dailySchedules } = require('./fields/daily-schedules')

const imageSchema = yup.object().shape({
  description: yup.string().ensure(),
  cloudinaryFile: uploadSchema,
})

const shape = labelify({
  restrictToUsers: yup.array().default([]),
  restrictToUserType: yup.string().oneOf(mobileUserTypes.concat('')).ensure(),
  name: yup.string().default(''),
  media: yup.array().of(imageSchema).default([]),
  schedule: yup.string().oneOf(['immediate', 'scheduled', 'event']).default('immediate'),
  scheduleAt: scheduleDate(),
  scheduleEndAt: scheduleDate(),
  dailySchedules: dailySchedules(),
  appearance: yup
    .string()
    .when('contentSource', { is: 'embed', then: (s) => s.oneOf(['normal', 'maximized', 'fullscreen']), otherwise: (s) => s.oneOf(['normal', 'maximized']) })
    .default('normal'),
  displayLocation: displayLocation(),
  filterDevices: yup.string().oneOf(['all', 'selected', 'none']).default('all'),
  devices: yup.array().of(yup.string()).default([]),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
  sortOrder: yup.number().min(0),
  published: yup.boolean().nullable().default(true),
})

const albumSchemaPartial = yup.object().shape(shape)
const albumSchema = albumSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { albumSchema, albumSchemaPartial }
