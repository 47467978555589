const yup = require('yup')
const mobileUserTypes = require('../mobile-user-types')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  name: yup.string().default(''),
  details: yup.string().default(''),
  resort: yup.mixed(),
  restrictToUserType: yup.string().oneOf(mobileUserTypes.concat('')).ensure(),
  displayOnFeedScreen: yup.boolean().default(true),
  icalImport: yup.object().shape({
    url: yup.string().nullable().transform((v) => v || null),
    active: yup.boolean().default(false),
  }),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
  createdAt: yup.date(),
  updatedAt: yup.date(),
})

const calendarSchemaPartial = yup.object().shape(shape)
const calendarSchema = calendarSchemaPartial.shape(requiredFields(['name'])(shape))

const calendarResponseSchema = calendarSchema.shape({
  icalImport: yup.object().shape({
    url: yup.string().ensure(),
    active: yup.boolean().default(false),
    lastImportedAt: yup.date().nullable(),
    lastImportStatus: yup.string().nullable(),
    lastImportMessage: yup.string().nullable(),
  }),
})

const calendarFormSchema = calendarResponseSchema

module.exports = { calendarSchema, calendarSchemaPartial, calendarResponseSchema, calendarFormSchema }
