const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  name: yup.string().default(''),
  description: yup.string().default(''),
  intervalType: yup.string().oneOf(['distance', 'time']).default('distance'),
  distanceInterval: yup.number().min(0).default(0),
  distanceBeforeNotification: yup.number().min(0),
  mileageOnStart: yup.number().min(0),
  startAt: yup
    .date()
    .default(() => new Date())
    .when(['intervalType'], (intervalType, s) => {
      if (intervalType === 'time') {
        return s.required()
      }
    }),
  timeInterval: yup.number().oneOf([1, 3, 6, 12]).min(1).default(1),
  daysBeforeNotification: yup.number().min(1).max(365),
  locationVehicle: yup.mixed(),
  maintenanceRequired: yup.boolean().default(false),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const locationVehicleMaintenanceScheduleSchemaPartial = yup.object().shape(shape)
const locationVehicleMaintenanceScheduleSchema = locationVehicleMaintenanceScheduleSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { locationVehicleMaintenanceScheduleSchema, locationVehicleMaintenanceScheduleSchemaPartial }
