const { compose, isEmpty, pick, mapObjIndexed } = require('ramda')
const { humanize } = require('underscore.string')
const yup = require('yup')

// See https://objectpartners.com/2020/06/04/validating-optional-objects-with-yup/
yup.addMethod(yup.object, 'optional', function ({ isOptional = true, defaultValue = undefined } = {}) {
  return this.transform((value) => {
    if (!isOptional || !isEmpty(value) || value === null) return value
    return defaultValue
  }).default(defaultValue)
})

const requiredFields = (fieldnames) =>
  compose(
    mapObjIndexed((v) => v.required()),
    pick(fieldnames)
  )

const labelify = (shape, options = {}) =>
  mapObjIndexed((definition, name) => {
    if (options.exclude && options.exclude.includes(name)) {
      return definition
    }

    return definition.label(humanize(name))
  }, shape)

module.exports = { requiredFields, labelify }
