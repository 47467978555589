import { Typography } from '@material-ui/core'
import { DateTime } from 'luxon'
import PropTypes from 'prop-types'
import React from 'react'
import Person from './Person'

const Activity = ({ component: Component = 'div', action, time, user }) => {
  if (time) {
    time = typeof time === 'string' ? DateTime.fromISO(time) : DateTime.fromJSDate(time)
    time = `${time.toLocaleString(DateTime.TIME_SIMPLE)} ${time.toLocaleString(DateTime.DATE_SHORT)}`
    time = time.toLowerCase()
  }
  return time ? (
    <Component>
      <Typography variant="caption" color="textSecondary" gutterBottom>
        {action} {time}{' '}
        {user?.name ? (
          <>
            by <Person user={user} />
          </>
        ) : null}
      </Typography>
    </Component>
  ) : null
}

Activity.propTypes = {
  component: PropTypes.func,
  action: PropTypes.string,
  time: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  user: PropTypes.any,
}

export default Activity
