import { Container } from '@cerebral/react'
import * as Sentry from '@sentry/browser'
import throttle from 'lodash.throttle'
import React from 'react'
import ReactDOM from 'react-dom'
import { Workbox } from 'workbox-window'
import AppUI from './components/App'
import TenantLoader from './components/TenantLoader'
import client from './lib/util/api-client'
import { load } from './lib/util/zoho'
import cerebralApp from './modules/app'

if (process.env.NODE_ENV === 'production' && 'serviceWorker' in navigator) {
  let swRegistered = null
  const wb = new Workbox('/service-worker.js')
  const setUpdateAvailable = cerebralApp.getSequence('ui.setUpdateAvailable')

  wb.register().then((registered) => {
    swRegistered = registered

    registered.addEventListener('updatefound', () => {
      const { installing } = registered
      installing.addEventListener('statechange', () => {
        if (installing.state === 'activated') {
          setUpdateAvailable()
        }
      })
    })
  })

  const checkServiceWorker = () => {
    if (swRegistered) {
      swRegistered.update()
    }
  }

  const throttledCheckServiceWorker = throttle(checkServiceWorker, 5 * 60 * 1000, { leading: true, trailing: true })

  // Check for updates when focusing window.
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      throttledCheckServiceWorker()
    }
  })

  client.io.on('connect', checkServiceWorker)
}

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN, environment: process.env.REACT_APP_ENVIRONMENT_NAME || 'production' })
}

if (process.env.NODE_ENV === 'development') {
  client.io.on('update:assets', () => {
    window.location.reload()
  })
}

ReactDOM.render(
  <Container app={cerebralApp}>
    <TenantLoader>
      <AppUI />
    </TenantLoader>
  </Container>,
  document.getElementById('root')
)

load()
