const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')
const { uploadSchema } = require('./cloudinary-schema')
const timeField = require('./fields/time-field')
const dayToggles = require('./fields/day-toggles')

const getUploadSchema = ({ minHeight, minWidth }) => {
  return uploadSchema.clone().shape({
    height: yup.number().min(minHeight, `Image must be at least ${minHeight}px tall.`),
    width: yup.number().min(minWidth, `Image must be at least ${minWidth}px wide.`),
  })
}

const baseItemShape = yup.object().shape({
  _id: yup.string().nullable(),
  itemType: yup.string().oneOf(['heading', 'price', 'text', 'list']).default('heading'),
  title: yup.string().required().ensure(),
  description: yup.string().ensure(),
  hidden: yup.boolean().default(false),
})

const headingItemSchema = baseItemShape.shape({
  level: yup.string().oneOf(['h1', 'h2', 'h3', 'h4', 'h5']).default('h2'),
  startNewSection: yup.boolean().default(true),
})

const textItemSchema = baseItemShape.shape({
  title: yup.string().ensure().meta({ hidden: true }),
  description: yup.string().required().ensure().label('Text'),
})

const priceItemSchema = baseItemShape.shape({
  image: uploadSchema,
  price: yup.string().required().ensure().max(20).meta({ maxLength: 20 }).default('0'),
  soldOut: yup.boolean().default(false),
  makeAvailableAtMidnight: yup.boolean().default(false),
})

const listItemSchema = baseItemShape.shape({
  title: yup.string().ensure().meta({ hidden: true }),
  elements: yup.array().of(yup.string()).ensure(),
})

const itemSchemas = {
  heading: headingItemSchema,
  price: priceItemSchema,
  text: textItemSchema,
  list: listItemSchema,
}

const itemShape = yup.lazy(({ itemType }) => itemSchemas[itemType])

const shape = labelify({
  title: yup.string().default(''),
  subtitle: yup.string().default(''),
  items: yup.array().of(itemShape),
  duration: yup.number().nullable(),
  filterDevices: yup.string().oneOf(['all', 'selected', 'none']).default('all'),
  devices: yup.array().of(yup.string()).default([]),
  layout: yup.string().oneOf(['list', 'column', 'grid', 'sections', 'image-single-column', 'image-dual-column']).default('list'),
  imageLayoutSettings: yup.object().shape({
    backgroundImage: getUploadSchema({ minHeight: 2160, minWidth: 1730 }),
    pricingImage: getUploadSchema({ minHeight: 2000, minWidth: 3400 }),
    titleBackgroundColor: yup.string(),
    pricingBackgroundColor: yup.string(),
  }),
  textColor: yup.string().oneOf(['light', 'dark']).default('light'),
  prefix: yup.string().oneOf(['$', '']).ensure().default('$'),
  hideTitle: yup.boolean().default(false),
  hidePrice: yup.boolean().default(false),
  emphasizePrice: yup.boolean().default(true),
  displayOn: dayToggles(),
  timing: yup.object().shape(
    labelify({
      schedule: yup.string().oneOf(['always', 'between']).default('always'),
      start: timeField(),
      end: timeField(),
    })
  ),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const pricingSchemaPartial = yup.object().shape(shape)
const pricingSchema = pricingSchemaPartial.shape(requiredFields(['title'])(shape))

module.exports = { pricingSchema, pricingSchemaPartial, itemSchemas }
