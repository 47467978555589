import { connect } from '@cerebral/react'
import { Box, Checkbox, FormControlLabel, Grid, Link, Typography } from '@material-ui/core'
import { sequences, state } from 'cerebral'
import { DateTime } from 'luxon'
import React, { useEffect } from 'react'
import { dasherize, trim } from 'underscore.string'
import { getDomain } from '../../../../shared/brands'
import brand from '../../../lib/util/brand'
import QrCode from '../../blocks/QrCode'
import ActionHeader from '../../elements/ActionHeader'
import Notice from '../../elements/Notice'

const isQuiltt = brand.name === 'Quiltt'

const Organization = connect(
  {
    organization: state`account.organization`,
    location: state`account.location`,
    getRecipientCount: sequences`notifications.getRecipientCount`,
    recipientCount: state`notifications.recipientCount`,
    setMailArrivedAt: sequences`account.saveSettings`,
    timezone: state`account.currentUser.timezone`,
  },

  ({ organization, location, getRecipientCount, recipientCount, setMailArrivedAt, timezone }) => {
    const domain = getDomain(organization)
    const code = (domain && `https://${domain}/${organization?.mobileAccessCode?.code ? `org/${organization?.mobileAccessCode?.code}` : 'appinstall'}`) || ''
    const download = (code && `${trim(dasherize(organization?.name || 'download'), ' -')}.png`) || ''
    const dateMailArrivedAt = DateTime.fromISO(organization?.mailArrivedAt).setZone(timezone)
    const today = DateTime.now().setZone(timezone)

    useEffect(() => {
      if (organization && !location) {
        getRecipientCount({ audience: 'all' })
      }
    }, [organization, location])

    return (
      <Box>
        <ActionHeader title="Overview" section="Organization" />

        {!location && (
          <Grid item md={6} xs={12}>
            {isQuiltt && (
              <Box mt={1}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(today.hasSame(dateMailArrivedAt, 'day'))}
                      onChange={({ target: { checked } }) => setMailArrivedAt({ values: { mailArrivedAt: checked ? new Date() : null } })}
                    />
                  }
                  label="Mail is here?"
                />
              </Box>
            )}

            <Notice>
              <Typography variant="h2">{recipientCount}</Typography>
              <Typography variant="body2">Total app users</Typography>
            </Notice>

            <Box my={6} />

            <Typography variant="h3" gutterBottom>
              Mobile App Install Link
            </Typography>
            <Link href={code}>{code}</Link>
            <Box style={{ maxWidth: 400 }}>
              <QrCode code={code} download={download} width={1200} />
            </Box>
          </Grid>
        )}
      </Box>
    )
  }
)

export default Organization
