const yup = require('yup')

const emptyStringToNull = (v, o) => (o === '' ? null : v)

const statValueSchema = ({ allowString = false, valueLabel, required = false } = {}) => {
  let value = allowString ? yup.string().ensure() : yup.number().min(0).transform(emptyStringToNull).nullable()
  if (required) {
    value = value.required().label(valueLabel)
  }

  return yup.object().shape({
    value,
    stat: yup.mixed().nullable(),
  })
}

const openTotalSchema = ({ allowString = false, openRequired = false, totalRequired = false } = {}) =>
  yup.object().shape({
    open: statValueSchema({ allowString, valueLabel: 'Open', required: openRequired }),
    total: statValueSchema({ allowString, valueLabel: 'Total', required: totalRequired }),
  })

const shape = ({ allowString = false }) => {
  const schema = yup.object().shape({
    reportDate: yup.date(),
    updateLinkedStats: yup.string().oneOf(['static', 'dynamic']).default('dynamic'),
    operatingStatus: yup.string().ensure().required().label('Operating Status'),
    comments: yup.string().ensure(),
    commentsLinked: yup.boolean().default(false),
    latestSnowfall: yup.object().shape({
      daysAgo0: statValueSchema({ allowString }),
      daysAgo1: statValueSchema({ allowString }),
      daysAgo2: statValueSchema({ allowString }),
      daysAgo3: statValueSchema({ allowString }),
      daysAgo4: statValueSchema({ allowString }),
    }),
    baseDepth: yup.object().shape({
      min: statValueSchema({ allowString }),
      max: statValueSchema({ allowString }),
    }),
    snowDepth: yup.object().shape({
      base: statValueSchema({ allowString }),
      mid: statValueSchema({ allowString }),
      summit: statValueSchema({ allowString }),
    }),
    surfaceConditions: yup.object().shape({
      primary: yup.string().ensure(),
      secondary: yup.string().ensure(),
      nightGroomingCount: yup.number().default(0),
    }),
    lifts: yup.object().when('publishTo', (value) => {
      const openRequired = value && value.onTheSnow
      const totalRequired = value && value.onTheSnow
      return openTotalSchema({ allowString, openRequired, totalRequired })
    }),
    trails: openTotalSchema({ allowString }),
    terrainAcres: openTotalSchema({ allowString }),
    terrainLength: openTotalSchema({ allowString }),
    publishTo: yup.object().shape({
      onTheSnow: yup.boolean().default(false),
      snoCountry: yup.boolean().default(false),
    }),
    reportStatus: yup.string().oneOf(['pending', 'publishing', 'succeeded', 'failed']).default('pending'),
    publishStatus: yup.object().shape({
      onTheSnow: yup.string().oneOf(['pending', 'publishing', 'succeeded', 'failed']).default('pending'),
      snoCountry: yup.string().oneOf(['pending', 'publishing', 'succeeded', 'failed']).default('pending'),
    }),
    createdAt: yup.date(),
    updatedAt: yup.date(),
    createdBy: yup.mixed(),
    updatedBy: yup.mixed(),
  })

  return schema
}

const snowReportSchemaPartial = shape({ allowString: false })
const snowReportSchema = snowReportSchemaPartial
const snowReportFormSchema = shape({ allowString: true })

module.exports = { snowReportSchema, snowReportSchemaPartial, snowReportFormSchema }
