const yup = require('yup')
const { requiredFields, labelify } = require('./helpers')

const shape = labelify({
  name: yup.string().default(''),
  ridesIncluded: yup.number().required().min(1).default(1),
  rideProductType: yup.string().oneOf(['anytime', 'daily']).default('anytime'),
  startAt: yup
    .date()
    .required()
    .default(() => new Date()),
  expireAt: yup
    .date()
    .min(yup.ref('startAt'))
    .default(() => new Date()),
  sku: yup.string().required().label('SKU'),
  createdAt: yup.date(),
  updatedAt: yup.date(),
  createdBy: yup.mixed(),
  updatedBy: yup.mixed(),
})

const rideProductSchemaPartial = yup.object().shape(shape)
const rideProductSchema = rideProductSchemaPartial.shape(requiredFields(['name'])(shape))

module.exports = { rideProductSchema, rideProductSchemaPartial }
