import LuxonUtils from '@date-io/luxon'

let currentTimezone = null

export const setCurrentTimezone = (timezone) => {
  currentTimezone = timezone
}

export const getCurrentTimezone = () => currentTimezone

// Use a custom utils set to the current organization timezone.
class DateUtils extends LuxonUtils {
  date(...args) {
    const dt = super.date(...args)
    return dt ? dt.setZone(currentTimezone) : dt
  }

  // NOTE: getWeekdays & getWeekArray customize the MUI calendar to start on Sunday.
  // From https://codesandbox.io/s/material-ui-pickers-usage-demo-w9n8i?file=/src/App.jsx

  getWeekdays() {
    return ['S', 'M', 'T', 'W', 'T', 'F', 'S']
  }

  getWeekArray(date) {
    const { days } = date.endOf('month').endOf('week').plus({ weeks: 1 }).diff(date.startOf('month').startOf('week'), 'days').toObject()

    let weeks = []
    new Array(Math.ceil(days))
      .fill(0)
      .map((_, i) => i)
      .map((day) => date.startOf('month').startOf('week').minus({ days: 1 }).plus({ days: day }))
      .forEach((v, i) => {
        if (i === 0 || (i % 7 === 0 && i > 6)) {
          weeks.push([v])
          return
        }

        weeks[weeks.length - 1].push(v)
      })

    weeks = weeks.filter((week) => {
      // Do not allow weeks without start or end inside of current month.
      return week[0].hasSame(date, 'month') || week[week.length - 1].hasSame(date, 'month')
    })

    return weeks
  }
}

export default DateUtils
