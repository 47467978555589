import { Box, Button, Card, Container } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { Form, Formik } from 'formik'
import PropTypes from 'prop-types'
import { identity, gt } from 'ramda'
import React, { useCallback, useState } from 'react'
import { JsonView } from 'react-json-view-lite'
import 'react-json-view-lite/dist/index.css'
import appModules from '../../lib/util/app-modules'
import ConfirmDialog from '../controls/ConfirmDialog'
import ThemedButton from '../controls/ThemedButton'
import ActionHeader from '../elements/ActionHeader'
import ActivityList from '../elements/ActivityList'

export const Submit = ({ submitLabel, creating, isSubmitting }) => (
  <Button color="primary" type="submit" size="large" disabled={isSubmitting}>
    {submitLabel || (creating ? 'Create' : 'Save')}
  </Button>
)

Submit.propTypes = {
  submitLabel: PropTypes.node,
  creating: PropTypes.bool,
  values: PropTypes.object,
  setFieldValue: PropTypes.func,
  submitForm: PropTypes.func,
  isSubmitting: PropTypes.bool,
}

const Remove = ({ isSubmitting, onClick }) => (
  <ThemedButton variant="outlined" color="danger" size="large" type="button" disabled={isSubmitting} onClick={onClick}>
    Delete
  </ThemedButton>
)

Remove.propTypes = {
  isSubmitting: PropTypes.bool,
  onClick: PropTypes.func,
}

const debugFromStorage = localStorage.getItem('debugAdminForm')

const defaultTitle = ({ type, creating, values }) => {
  const appModule = (type && appModules[type]) || {}
  const { displayType = type } = appModule
  let title = `${creating ? 'New' : 'Editing'} ${displayType}`

  if (!creating && appModule.title) {
    title = `${title}: ${appModule.title(values)}`
  }
  return title
}

const ValidatedForm = ({
  schema,
  initialValues = {},
  children,
  submitLabel,
  actionButtons,
  activity = [],
  title,
  section,
  action,
  type,
  submitComponent,
  cardComponent,
  submitExtra,
  submitExtraContainerStyle = {},
  onCancel,
  error,
  // This is an unpleasant workaround for https://github.com/formium/formik/issues/568
  transformInitialValues = identity,
  debug,
  removeComponent,
  handleRemove,
  ...rest
}) => {
  const SubmitComponent = submitComponent || Submit
  const RemoveComponent = removeComponent || Remove
  const CardComponent = cardComponent || Card

  const [modalVisible, setModalVisible] = useState(false)

  return (
    <Formik enableReinitialize initialValues={transformInitialValues(schema.cast(initialValues))} validationSchema={schema} {...rest}>
      {(props) => {
        const { values, errors, dirty, touched } = props
        // Title should always be a string, but we allow a function that returns a string.
        let valueTitle
        if (typeof title === 'function') {
          valueTitle = title(values)
        }
        let valueSubmitExtra
        if (typeof submitExtra === 'function') {
          valueSubmitExtra = submitExtra(props)
        }

        const creating = !values._id || action === 'duplicate'
        const activities = !creating && <ActivityList activity={activity} values={values} />

        const moduleDisplayType = appModules[type]?.displayType || type
        const removeDialogue = (
          <ConfirmDialog
            title={`Delete ${moduleDisplayType}`}
            fullWidth
            onClose={() => setModalVisible(false)}
            onConfirm={() => {
              setModalVisible(false)
              handleRemove()
            }}
            confirmText="OK"
            open={modalVisible}
          >
            Are you sure you want to delete this <strong>{moduleDisplayType}</strong>?
          </ConfirmDialog>
        )

        const navigateBack = useCallback(() => window.history.back())

        return (
          <Container maxWidth="md">
            {removeDialogue}
            <Box mb={6}>
              <Form>
                {(title || type) && (
                  <ActionHeader title={valueTitle || title || defaultTitle({ type, creating, values })} section={section}>
                    <Box ml={2}>
                      {actionButtons}
                      <Button size="large" variant="text" onClick={onCancel || navigateBack}>
                        Cancel
                      </Button>
                    </Box>
                  </ActionHeader>
                )}
                {Boolean(debug || debugFromStorage) && (
                  <>
                    <Box margin={1}>
                      <JsonView data={values} shouldExpandNode={gt(1)} />
                    </Box>
                    <Box margin={1}>
                      <JsonView data={{ errors, dirty, touched }} shouldExpandNode={gt(1)} />
                    </Box>
                  </>
                )}
                <CardComponent>
                  <Box p={6}>
                    {typeof children === 'function' ? children(props) : children}
                    {error && (
                      <Box my={2}>
                        <Alert severity="error">{error.message}</Alert>
                      </Box>
                    )}
                    <Box mt={3} mb={activities ? 5 : 1} display="flex" alignItems="center" flexDirection="row-reverse">
                      <SubmitComponent {...props} submitLabel={submitLabel} creating={creating} />
                      {submitExtra && (
                        <Box mr={3} style={submitExtraContainerStyle}>
                          {valueSubmitExtra || submitExtra}
                        </Box>
                      )}
                      {handleRemove && !creating && (
                        <Box mr={3} style={{ flex: 1 }}>
                          <RemoveComponent onClick={() => setModalVisible(true)} isSubmitting={props.isSubmitting} />
                        </Box>
                      )}
                    </Box>
                    {activities}
                  </Box>
                </CardComponent>
              </Form>
            </Box>
          </Container>
        )
      }}
    </Formik>
  )
}

ValidatedForm.propTypes = {
  ...Formik.propTypes,
  actionButtons: PropTypes.node,
  schema: PropTypes.object,
  activity: PropTypes.array,
  submitLabel: PropTypes.string,
}

export default ValidatedForm
