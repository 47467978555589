import { find } from 'ramda'
import alpineTheme from '../../theme/cc-alpine-color-theme.json'
import campusHubTheme from '../../theme/cc-campushub-color-theme.json'
import hoamTheme from '../../theme/cc-hoam-color-theme.json'
import quilttTheme from '../../theme/cc-quiltt-color-theme.json'
import AlpineLogo from '../../theme/cc-alpine-logo.svg'
import CampusHubLogo from '../../theme/cc-campushub-logo.svg'
import HoamLogo from '../../theme/cc-hoam-logo.svg'
import QuilttLogo from '../../theme/cc-quiltt-logo.svg'
import AlpineLogoStacked from '../../theme/cc-alpine-logo-stacked.svg'
import CampusHubLogoStacked from '../../theme/cc-campushub-logo-stacked.svg'
import HoamLogoStacked from '../../theme/cc-hoam-logo-stacked.svg'
import QuilttLogoStacked from '../../theme/cc-quiltt-logo-stacked.svg'

const brands = {
  'alpinemedia.com': {
    name: 'Alpine',
    indefiniteArticle: 'an',
    email: 'support@alpinemedia.com',
    theme: alpineTheme,
    logo: AlpineLogo,
    logoStacked: AlpineLogoStacked,
    docsUrl: 'https://support.alpinemedia.com',
    zohoWidgetCode: 'siq32fc3261808623305722347ccef8eb52639cb414c90b13dc3097cb060e35eaac',
  },
  'campushub.io': {
    name: 'CampusHub',
    indefiniteArticle: 'a',
    email: 'support@campushub.io',
    theme: campusHubTheme,
    logo: CampusHubLogo,
    logoStacked: CampusHubLogoStacked,
    docsUrl: '',
    zohoWidgetCode: 'siq1fd44351ce2ab4cf93eae7ec48fa8000e3c64fcdd8f501278ddbadf902f5badb',
  },
  'hoam.tech': {
    name: 'HOAM',
    indefiniteArticle: 'a',
    email: 'support@hoam.tech',
    theme: hoamTheme,
    logo: HoamLogo,
    logoStacked: HoamLogoStacked,
    docsUrl: 'https://loom.com/share/folder/8fe750d9763d4c128d1cc50feb7d5b5e',
    zohoWidgetCode: 'siqe815042c87b1ab1affb4e4c9ed847321',
  },
  'quiltt.com': {
    name: 'Quiltt',
    indefiniteArticle: 'a',
    email: 'support@quiltt.com',
    theme: quilttTheme,
    logo: QuilttLogo,
    logoStacked: QuilttLogoStacked,
    docsUrl: 'https://support.quiltt.com',
    zohoWidgetCode: 'siq0e53e71f3911a4663eb2acfcb728d801aa971a8ab6d0a509d431915ac57250cc',
  },
}

const { hostname } = window.location
const key =
  find((domain) => hostname.endsWith(domain), Object.keys(brands)) ||
  (process.env.__DEV__ && (localStorage.TEST_BRAND_DOMAIN || process.env.TEST_BRAND_DOMAIN)) ||
  'alpinemedia.com'
const brand = brands[key]

export default brand
