import { connect } from '@cerebral/react'
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  Tooltip,
  Typography,
  makeStyles,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
} from '@material-ui/core'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { sequences, state } from 'cerebral'
import { Field, FieldArray, Form, Formik } from 'formik'
import { CheckboxWithLabel, RadioGroup, TextField } from 'formik-material-ui'
import { DatePicker, TimePicker } from 'formik-material-ui-pickers'
import { difference, find, pick, pluck, propEq, isEmpty, keys, pickBy } from 'ramda'
import { isNumber } from 'ramda-adjunct'
import React, { useEffect, useMemo, useState } from 'react'
import { capitalize } from 'underscore.string'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { DragIndicator, Visibility, VisibilityOff } from '@material-ui/icons'
import { settingsFormSchema } from '../../../../shared/schemas/resort-schema'
import { omitStateFlag } from '../../../lib/util/cerebral'
import { useToggleState } from '../../../lib/util/hooks'
import ValidatedForm from '../../blocks/ValidatedForm'
import ConfirmDialog from '../../controls/ConfirmDialog'
import DropUpload from '../../controls/DropUpload'
import FieldSpacing from '../../elements/FieldSpacing'
import Notice from '../../elements/Notice'
import brand from '../../../lib/util/brand'
import LimitedTextField from '../../controls/LimitedTextField'

const isQuiltt = brand.name === 'Quiltt'

const useStyles = makeStyles((theme) => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  active: {
    backgroundColor: theme.palette.grey[100],
    border: `1px solid ${theme.palette.grey[300]}`,
  },
}))

const sectionNames = {
  albums: 'Albums',
  surveys: 'Surveys',
  reservations: 'Reservations',
  weather: 'Weather',
  menu: 'Menu',
  liftsAndTrails: 'Lifts And Trails',
  snowConditions: 'Snow Conditions',
  events: 'Events',
  media: 'Media',
  mountainCams: 'Resort Cameras',
  resources: 'Resources',
  engagementBundle: 'Engagement Bundle',
}

const Settings = connect(
  {
    organization: state`account.organization`,
    sections: state`account.sections`,
    findDevices: sequences`kioskDevices.findForList`,
    kioskDevices: state`kioskDevices.list.data`,
    getRecipientCount: sequences`notifications.getRecipientCount`,
    recipientCount: state`notifications.recipientCount`,
    saveSettings: sequences`account.saveSettings`,
    saveSeasons: sequences`seasons.save`,
    timezone: state`account.currentUser.timezone`,
    findResortSeasons: sequences`seasons.findForList`,
    resortSeasons: state`seasons.list.data`,
    packages: state`account.packages`,
    serviceIntegrationSettings: state`account.currentUser.serviceIntegrationSettings`,
  },

  (props) => ({
    ...props,
    saveSettings: (values) => props.saveSettings({ values: pick(['address', 'logo', 'seasons', 'birthdayUpdates'], values) }),
  }),

  ({ organization, sections, saveSettings, findResortSeasons, saveSeasons, resortSeasons = [], packages, serviceIntegrationSettings = {} }) => {
    const classes = useStyles()

    const [modalVisible, openModal, closeModal] = useToggleState(false)
    const [datePicker, setDatePicker] = useState('now')
    const hasMultipleSeasons = useMemo(() => resortSeasons.length > 1, [resortSeasons])
    const hasMobileApp = packages['notifications']
    const showMultipleSeasonUI = hasMultipleSeasons && hasMobileApp

    useEffect(() => {
      findResortSeasons()
    }, [])

    const actions = [
      {
        label: 'Switch seasons',
        onClick: () => openModal(),
      },
    ]

    const actionButtons = actions
      .map(({ label, onClick }, index) => {
        return (
          hasMultipleSeasons && (
            <Button key={index} onClick={onClick} color="primary" style={{ marginRight: 10 }} variant="outlined">
              {label}
            </Button>
          )
        )
      })
      .filter(Boolean)

    const switchSeason = ({ activeAfter }) => {
      const inactiveSeason = find(propEq('active', false))(resortSeasons)
      if (inactiveSeason) {
        saveSeasons({ values: { ...inactiveSeason, activeAfter, active: datePicker === 'now' } })
      }
    }

    return organization?._id ? (
      <>
        <Formik
          initialValues={{
            activeAfter: new Date(),
          }}
          onSubmit={switchSeason}
        >
          {({ setFieldValue, handleSubmit }) => {
            return (
              <Form>
                <ConfirmDialog title="Switch seasons" fullWidth onClose={closeModal} onConfirm={handleSubmit} confirmText="OK" open={modalVisible}>
                  <Typography variant="body1">Do you want to switch to the new season ?</Typography>

                  <Grid container>
                    <FieldSpacing>
                      <RadioGroup>
                        <FormControlLabel
                          onChange={({ target: { value } }) => {
                            setDatePicker(value)
                            setFieldValue('activeAfter', new Date())
                          }}
                          value="now"
                          control={<Radio checked={datePicker === 'now'} />}
                          label="Switch now"
                        />

                        <Grid container direction="row" alignItems="center">
                          <Grid item md={3}>
                            <FormControlLabel
                              onChange={({ target: { value } }) => setDatePicker(value)}
                              value="date"
                              control={<Radio checked={datePicker === 'date'} />}
                              label="Switch on"
                            />
                          </Grid>

                          <Grid item md={9}>
                            <Grid container spacing={2}>
                              <Grid item xs={12} md={6}>
                                <Field
                                  disabled={datePicker === 'now'}
                                  size="small"
                                  required
                                  label="Start Date"
                                  name="activeAfter"
                                  format="DD"
                                  variant="inline"
                                  component={DatePicker}
                                  minDate={new Date()}
                                />
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <Field
                                  disabled={datePicker === 'now'}
                                  size="small"
                                  required
                                  label="Start Time"
                                  name="activeAfter"
                                  variant="inline"
                                  component={TimePicker}
                                  minutesStep={5}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </RadioGroup>
                    </FieldSpacing>
                  </Grid>
                </ConfirmDialog>
              </Form>
            )
          }}
        </Formik>

        <ValidatedForm
          title="Settings"
          section="Organization"
          initialValues={organization}
          schema={settingsFormSchema}
          onSubmit={saveSettings}
          actionButtons={actionButtons}
        >
          {({ values, setFieldValue, errors }) => {
            const { logo, seasons, birthdayUpdates: { automaticallyCreate } = {} } = values

            const sectionsWithVirtual = useMemo(() => {
              const engagementBundle = Boolean(serviceIntegrationSettings.engagementBundle)
              return {
                ...sections,
                engagementBundle: engagementBundle ? true : undefined,
              }
            }, [sections, serviceIntegrationSettings])

            const sectionNamesWithOverrides = useMemo(() => {
              const { display: { name } = {} } = serviceIntegrationSettings.engagementBundle || {}
              if (name) {
                return { ...sectionNames, engagementBundle: name }
              }
              return sectionNames
            }, [serviceIntegrationSettings])

            const enabledMobileFeedSections = useMemo(() => keys(pickBy((v, k) => v && sectionNames[k], sectionsWithVirtual)), [sectionsWithVirtual])

            useEffect(() => {
              const seasons = resortSeasons.map((season) => {
                let { mobileFeedSections = [] } = season
                const currentFeedSections = pluck('mobileSection', mobileFeedSections || [])
                const notEnabledInFeed = difference(enabledMobileFeedSections, currentFeedSections)
                mobileFeedSections = mobileFeedSections.concat(notEnabledInFeed.map((mobileSection) => ({ mobileSection, visible: false })))
                return { ...season, mobileFeedSections }
              })

              setFieldValue('seasons', seasons)
            }, [enabledMobileFeedSections, resortSeasons, organization?.address, organization?.logo])

            return (
              <>
                {(!isNumber(organization.latitude) || !isNumber(organization.longitude) || !organization.timezone) && (
                  <Notice variant="info">Please enter an address for {organization.name} to finish setting up your account.</Notice>
                )}

                <Grid spacing={4} container>
                  <Grid md={6} xs={12} item>
                    <Typography variant="h4">Address</Typography>
                    <Field label="Street" name="address.street1" component={TextField} />
                    <Field name="address.street2" component={TextField} />
                    <Field label="City" name="address.city" component={TextField} />
                    <Field label="State" name="address.state" component={TextField} />
                    <Field label="Zip" name="address.zip" component={TextField} />
                  </Grid>

                  <Grid md={6} xs={12} item>
                    <Typography variant="h4" gutterBottom>
                      Logo
                    </Typography>
                    <DropUpload optional ratio={9 / 16} image={omitStateFlag(logo)} onChange={(image) => setFieldValue('logo', image)} error={errors.logo} />
                  </Grid>
                </Grid>

                {isQuiltt && (
                  <>
                    <Box mt={3} mb={3}>
                      <Divider />
                    </Box>
                    <Box mb={2}>
                      <Typography variant="h4">Notifications</Typography>
                    </Box>
                    <Field
                      Label={{ label: 'Automatically create birthday announcements?' }}
                      name="birthdayUpdates.automaticallyCreate"
                      type="checkbox"
                      component={CheckboxWithLabel}
                    />
                    {automaticallyCreate && (
                      <>
                        <Notice>
                          On a resident&apos;s birthday, a notification for signage will be automatically created. The following placeholders will be replaced with resident
                          information: {`{{first_name}}, {{last_name}}, {{first_initial}}, {{last_initial}}`}
                        </Notice>
                        <Field
                          required
                          label="Primary Update Template"
                          name="birthdayUpdates.primaryTemplate"
                          schema={settingsFormSchema}
                          component={LimitedTextField}
                        />
                        <Field
                          multiline
                          rows="4"
                          label="Details Template (optional)"
                          name="birthdayUpdates.detailsTemplate"
                          schema={settingsFormSchema}
                          component={LimitedTextField}
                        />
                      </>
                    )}
                  </>
                )}

                {showMultipleSeasonUI && (
                  <>
                    <Box mt={3} mb={3}>
                      <Divider />
                    </Box>
                    <Box mb={2}>
                      <Typography variant="h4">Resort Seasons</Typography>
                    </Box>
                  </>
                )}

                <Grid spacing={2} container>
                  {hasMobileApp
                    ? seasons.map((season, index) => {
                        const { _id: id, slug, active, coverPhoto, mobileHeaderImage, mobileFeedSections = [] } = season
                        const showActiveIndicator = active && hasMultipleSeasons

                        return (
                          <Grid key={id} item md={6} xs={12}>
                            <Box p={3} className={`${classes.paper} ${showActiveIndicator && classes.active}`}>
                              {showMultipleSeasonUI ? (
                                <Box display="flex" flexDirection="row" justifyContent="space-between" height={24} mb={2}>
                                  <Typography variant="h5">{capitalize(slug)}</Typography>
                                  <Box>{showActiveIndicator && <Chip color="primary" label="Active" />}</Box>
                                </Box>
                              ) : null}

                              <Grid md={12} xs={12} item style={{ marginBottom: 30 }}>
                                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} mt={1}>
                                  <Typography variant="h6">Cover Photo</Typography>
                                  <Tooltip
                                    title={
                                      <>
                                        Shown at the top of the menu screen of the mobile app.
                                        <br />
                                        (Max size 10 MB; 5:3 aspect ratio)
                                      </>
                                    }
                                    arrow
                                  >
                                    <InfoIcon />
                                  </Tooltip>
                                </Box>

                                <DropUpload
                                  ratio={3 / 5}
                                  crop="fill"
                                  image={omitStateFlag(coverPhoto)}
                                  onChange={(coverPhoto) => setFieldValue(`seasons.${index}.coverPhoto`, coverPhoto)}
                                  error={errors?.seasons?.[index]?.coverPhoto}
                                />
                              </Grid>

                              <Grid md={12} xs={12} item>
                                <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} mt={1}>
                                  <Typography variant="h6">Mobile Header Image</Typography>
                                  <Tooltip
                                    title={
                                      <>
                                        Shown at the top of the main screen of the mobile app.
                                        <br />
                                        (Max size 10 MB; 1:1 aspect ratio)
                                      </>
                                    }
                                    arrow
                                  >
                                    <InfoIcon />
                                  </Tooltip>
                                </Box>

                                <DropUpload
                                  ratio={1}
                                  crop="fill"
                                  image={omitStateFlag(mobileHeaderImage)}
                                  onChange={(mobileHeaderImage) => setFieldValue(`seasons.${index}.mobileHeaderImage`, mobileHeaderImage)}
                                  error={errors?.seasons?.[index]?.mobileHeaderImage}
                                />
                              </Grid>

                              {isEmpty(mobileFeedSections) ? null : (
                                <>
                                  <Box mt={3} mb={3}>
                                    <Divider />
                                  </Box>

                                  <Box display="flex" alignItems="center" justifyContent="space-between" mb={1} mt={1}>
                                    <Typography variant="h6">Mobile Feed Sections</Typography>
                                    <Tooltip title="You can sort the order and set visibility of sections shown on the mobile app feed screen" arrow>
                                      <InfoIcon />
                                    </Tooltip>
                                  </Box>

                                  <br />

                                  <FieldArray
                                    name={`seasons.${index}.mobileFeedSections`}
                                    render={({ move }) => (
                                      <DragDropContext onDragEnd={({ source: { index: from }, destination: { index: to } }) => move(from, to)}>
                                        <Droppable droppableId="mobileSectionList">
                                          {(provided) => (
                                            <div {...provided.droppableProps} ref={provided.innerRef}>
                                              {mobileFeedSections.map((section, sectionIndex) => {
                                                const { mobileSection } = section
                                                return (
                                                  <Draggable key={mobileSection} draggableId={mobileSection} index={sectionIndex}>
                                                    {(provided) => (
                                                      <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                                        <ListItem disableGutters ContainerComponent="div">
                                                          <ListItemText primary={sectionNamesWithOverrides[mobileSection]} />

                                                          <ListItemSecondaryAction>
                                                            <Box display="flex" flexDirection="row" alignItems="center">
                                                              <IconButton
                                                                aria-label="visibility"
                                                                onClick={() => {
                                                                  setFieldValue(`seasons.${index}.mobileFeedSections.${sectionIndex}.visible`, !section.visible)
                                                                }}
                                                              >
                                                                {section.visible ? <Visibility color="secondary" /> : <VisibilityOff />}
                                                              </IconButton>
                                                              <DragIndicator color="secondary" />
                                                            </Box>
                                                          </ListItemSecondaryAction>
                                                        </ListItem>
                                                        <Divider />
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                )
                                              })}
                                              {provided.placeholder}
                                            </div>
                                          )}
                                        </Droppable>
                                      </DragDropContext>
                                    )}
                                  />
                                </>
                              )}
                            </Box>
                          </Grid>
                        )
                      })
                    : null}
                </Grid>

                <br />
              </>
            )
          }}
        </ValidatedForm>
      </>
    ) : null
  }
)

export default Settings
