const yup = require('yup')
const { labelify } = require('./helpers')

const shape = labelify({
  uid: yup.string(),
  date: yup.date(),
  attendeeCount: yup.number().default(0),
  event: yup.mixed(),
})

const eventInstanceSchemaPartial = yup.object().shape(shape)
const eventInstanceSchema = eventInstanceSchemaPartial

const eventInstanceResponseSchema = eventInstanceSchema.shape({
  _id: yup.mixed(),
  event: yup
    .object()
    .shape({
      _id: yup.mixed(),
      title: yup.string().default(''),
      label: yup.string().default(''),
      startAt: yup.date(),
      endAt: yup.date(),
      allDay: yup.boolean(),
      repeatType: yup.string(),
      image: yup
        .object()
        .shape({
          secure_url: yup.string().default(''),
          url: yup.string().default(''),
          created_at: yup.date(),
          type: yup.string().default(''),
          public_id: yup.string(),
          format: yup.string().default('jpg'),
          resource_type: yup.string().default('image'),
          version: yup.number().default(0),
        })
        .nullable(),
      location: yup.string(),
      locationShortCode: yup.string(),
      locationLinkOnMobile: yup.string(),
      featured: yup.boolean().default(false),
    })
    .nullable(),
  attendance: yup
    .object()
    .shape({ _id: yup.mixed(), participated: yup.boolean().nullable(), participationStatus: yup.string().nullable(), notes: yup.string().nullable() })
    .nullable(),
  startAt: yup.date(),
  endAt: yup.date(),
  eventType: yup.string(),
})

module.exports = { eventInstanceSchema, eventInstanceSchemaPartial, eventInstanceResponseSchema }
