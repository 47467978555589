import { DateTime } from 'luxon'
import RRule from 'rrule'

// Handle data from API (Date) or string (ISO) or field (DateTime), returning DateTime.
export const toDateTime = (d, tz) => (d instanceof DateTime ? d : typeof d === 'string' ? DateTime.fromISO(d) : DateTime.fromJSDate(d)).setZone(tz)

export const isBirthdayToday = (date, timezone) => {
  if (!date) return false
  const birthday = toDateTime(date, timezone).set({ year: new Date().getFullYear() }).startOf('day')
  const today = DateTime.local({ zone: timezone }).startOf('day')
  return today.hasSame(birthday, 'day')
}

export const constructRepeatRule = ({ base, dt, repeatType }) => {
  const weekDay = dt.get('weekday') - 1
  const weekNo = Math.floor(dt.diff(dt.startOf('month')).as('weeks'))
  const rrules = {
    none: base,
    daily: { ...base, freq: RRule.DAILY },
    weekly: { ...base, freq: RRule.WEEKLY, byweekday: [weekDay] },
    monthly: { ...base, freq: RRule.MONTHLY, byweekday: [weekDay], bysetpos: weekNo + 1 },
    yearly: { ...base, freq: RRule.YEARLY, bymonth: dt.month, bymonthday: dt.day },
    custom: base,
  }
  return rrules[repeatType]
}
