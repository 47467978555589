import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Typography } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import * as Sentry from "@sentry/browser"
import { connect } from '@cerebral/react'
import { sequences } from 'cerebral'

class ErrorBoundary extends Component {
  state = { hasError: false, error: null }

  static getDerivedStateFromError(error) {
    return { hasError: true, error }
  }

  componentDidCatch(error, errorInfo) {
    const { tryToRecover } = this.props
    if (error.message.includes('ChunkLoadError')) {
      tryToRecover()
    } else if (process.env.REACT_APP_SENTRY_DSN) {
      Sentry.captureException(error, { extra: errorInfo })
    }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Alert severity="error">
          {this.props.errorContent || <Typography variant="h4">We&apos;re sorry, something went wrong.</Typography>}
          <Typography>We&apos;ve logged the error to figure out what caused it, but you&apos;ll need to reload your browser tab to try again.</Typography>
        </Alert>
      )
    }

    return this.props.children
  }
}

ErrorBoundary.propTypes = {
  tryToRecover: PropTypes.func,
  errorContent: PropTypes.node,
  children: PropTypes.node,
}

export default connect(
  {
    tryToRecover: sequences`ui.tryToRecover`,
  },
  ErrorBoundary,
)
