import { Box, Divider } from '@material-ui/core'
import { prop } from 'ramda'
import React from 'react'
import { titleize } from 'underscore.string'
import Activity from './Activity'

const ActivityList = ({ activity = [], values = {} }) => {
  const activityEntries = activity.map((key) => [key, values[`${key}At`], values[`${key}By`]]).filter(prop(0))
  const showActivity = activityEntries.length > 0

  return (
    showActivity && (
      <Box mt={4}>
        <Divider />
        <Box my={1}>
          {activityEntries.map(([key, at, by]) => (
            <Activity key={key} action={titleize(key)} time={at} user={by} />
          ))}
        </Box>
      </Box>
    )
  )
}

export default ActivityList
