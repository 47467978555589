const yup = require('yup')
const { labelify, requiredFields } = require('./helpers')
const { uploadSchema } = require('./cloudinary-schema')
const { seasonSchema } = require('./season-schema')

const shape = labelify({
  mailArrivedAt: yup.date().nullable(),
  parent: yup.mixed(),
})

const settingsSchema = yup.object().shape({
  logo: uploadSchema,
  address: yup
    .object({
      street1: yup.string(),
      street2: yup.string(),
      city: yup.string(),
      state: yup.string(),
      zip: yup.string(),
    })
    .optional(),
  mobileIntro: yup
    .object({
      contact: yup.string(),
    })
    .optional(),
  birthdayUpdates: yup
    .object()
    .shape({
      automaticallyCreate: yup.boolean(),
      primaryTemplate: yup.string(),
      detailsTemplate: yup.string(),
    })
    .optional(),
  socialMedia: yup
    .object({
      instagram: yup.string(),
      twitter: yup.string(),
      facebook: yup.string(),
      youTube: yup.string(),
      tikTok: yup.string(),
      vimeo: yup.string(),
    })
    .optional(),
})

const contactFormSchema = yup.object().shape({
  _id: yup.string(),
  mobileIntro: yup.object().shape({
    contact: yup.string().max(400),
  }),
  socialMedia: yup.object().shape({
    instagram: yup.string(),
    twitter: yup.string(),
    facebook: yup.string(),
    youTube: yup.string(),
    tikTok: yup.string(),
    vimeo: yup.string(),
  }),
})

const settingsFormSchema = yup.object().shape({
  _id: yup.string(),
  logo: uploadSchema,
  seasons: yup.array().of(seasonSchema).default([]),
  address: yup.object().shape(
    requiredFields(['street1', 'city', 'state', 'zip'], {
      street1: yup.string().ensure(),
      street2: yup.string().ensure(),
      city: yup.string().ensure(),
      state: yup.string().ensure(),
      zip: yup.string().ensure(),
    })
  ),
  birthdayUpdates: yup.object().shape({
    automaticallyCreate: yup.boolean().default(false),
    primaryTemplate: yup.string().max(100).meta({ maxLength: 100 }).default('Happy Birthday {{first_name}} {{last_name}}!'),
    detailsTemplate: yup.string().default('🎉 Happy Birthday {{first_name}} {{last_name}}! 🎉'),
  }),
})

const resortSchema = yup.object().shape(shape).concat(settingsSchema)

const responseSchema = resortSchema.shape({
  latitude: yup.number(),
  longitude: yup.number(),
  timezone: yup.string(),
  hasDescendants: yup.boolean().default(false),
})

module.exports = { resortSchema, settingsFormSchema, contactFormSchema, responseSchema }
