import React from 'react'
import PropTypes from 'prop-types'
import { Button, Typography, makeStyles, Box } from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  button: {
    padding: '11px 16px',
    borderColor: theme.palette.grey.A100,
  },
  label: {
    justifyContent: 'space-between',
  },
  title: {
    fontWeight: theme.typography.fontWeightRegular,
  },
  content: {
    color: theme.palette.text.secondary,
  },
}))

const ActionBlock = ({ title, children, ...props }) => {
  const classes = useStyles()

  return (
    <Button classes={{ root: classes.button, label: classes.label }} variant="outlined" color="primary" size="large" fullWidth {...props}>
      <Box textAlign="left">
        <Typography variant="h5" classes={{ root: classes.title }}>{title}</Typography>
        <Typography component="div" variant="caption" classes={{ root: classes.content }}>{children}</Typography>
      </Box>
    </Button>
  )
}

ActionBlock.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  ...Button.propTypes,
}

export default ActionBlock
