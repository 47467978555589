var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import { connect } from '@cerebral/react';
import { Box, Button, Snackbar } from '@material-ui/core';
import { sequences, state } from 'cerebral';
var TodaysBirthdays = connect({
    birthdays: state(templateObject_1 || (templateObject_1 = __makeTemplateObject(["profiles.todaysBirthdays"], ["profiles.todaysBirthdays"]))),
    acknowledgeBirthday: sequences(templateObject_2 || (templateObject_2 = __makeTemplateObject(["profiles.acknowledgeBirthday"], ["profiles.acknowledgeBirthday"]))),
    setUpdateForBirthday: sequences(templateObject_3 || (templateObject_3 = __makeTemplateObject(["updates.setUpdateForBirthday"], ["updates.setUpdateForBirthday"]))),
}, function (_a) {
    var _b = _a.birthdays, birthdays = _b === void 0 ? [] : _b, acknowledgeBirthday = _a.acknowledgeBirthday, setUpdateForBirthday = _a.setUpdateForBirthday;
    if (birthdays.length < 0) {
        return null;
    }
    return birthdays.map(function (_a, index) {
        var _b = _a === void 0 ? {} : _a, name = _b.name, id = _b._id;
        if (index === void 0) { index = 0; }
        return (React.createElement(Snackbar, { key: id, style: { marginBottom: index * 60 }, anchorOrigin: { vertical: 'bottom', horizontal: 'left' }, open: true, message: "Today is ".concat((name === null || name === void 0 ? void 0 : name.first) || (name === null || name === void 0 ? void 0 : name.display), "'s birthday"), action: React.createElement(Box, { style: { display: 'flex', flexDirection: 'row' } },
                React.createElement(Box, { mr: 2, style: { flex: 1 } },
                    React.createElement(Button, { color: "primary", onClick: function () { return acknowledgeBirthday({ id: id }); }, size: "small" }, "Dismiss")),
                React.createElement(Button, { color: "primary", onClick: function () { return setUpdateForBirthday({ profileId: id }); }, size: "small" }, "Announce on digital signage")) }));
    });
});
export default TodaysBirthdays;
var templateObject_1, templateObject_2, templateObject_3;
